body {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb:vertical {
  background: #6987d3;
}
::-webkit-scrollbar-thumb:horizontal {
  background: #6987d3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6987d3;
}
